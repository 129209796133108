import { registerKeapFormElement } from './keap-forms-processing';
import { initializeAnalytics } from '@/hosting/keap-analytics';
import { registerKeapEventElements, registerKeapHostingElement } from '@/hosting/keap-track';
import { processKeapLinks } from '@/hosting/keap-links';
import { processCalendly } from '@/hosting/calendly-appointments';

(function () {
    initializeAnalytics();
    registerKeapHostingElement();
    registerKeapFormElement();
    registerKeapEventElements();
    processKeapLinks();
    processCalendly();
}());

