import { documentLoaded } from '@/shared/dom-utils';

export async function processCalendlyKeapTags() {
    await documentLoaded();

    // Listen for Date & Time messages from Calendly
    window.addEventListener('message', function(event) {
    // Check if the message is from Calendly
        if (event.origin.startsWith('https://calendly.com')) {
            try {
                // Check if event.data is a string and parse it, otherwise use it directly
                var eventData = (typeof event.data === 'string') ? JSON.parse(event.data) : event.data;

                // Check if the event is 'calendly.event_scheduled'
                if (eventData.event && eventData.event === 'calendly.date_and_time_selected') {
                    // eslint-disable-next-line no-console
                    console.log('Calendly event scheduled:', eventData);

                    //capture returning date and time to local storage for use in the next step when schedule event fires
                    //enables the population of custom date and time fields for users to automate against

                }
            } catch (e) {
                // Catch any errors that may occur during parsing or processing the event data
                console.error('Error processing message from Calendly:', e);
            }
        }
    });
}
